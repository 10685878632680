import React from 'react';
import {
  BrowserRouter, Route, Routes,
} from 'react-router-dom';

import Home from '~/views/Home';
import NotFound from '~/views/NotFound';

import Rga from '~/views/Rga';
import RgaShow from '~/views/Rga/Show';

import Castration from '~/views/Castration';
import CastrationShow from '~/views/Castration/Show';

import Batch from '~/views/Batch';
import BatchShow from '~/views/Batch/Show';

import ClinicalCare from '~/views/ClinicalCare';
import ClinicalCareShow from '~/views/ClinicalCare/Show';

import AdoptionTerm from '~/views/AdoptionTerm';
import AdoptionTermList from '~/views/AdoptionTerm/List';
import AdoptionTermShow from '~/views/AdoptionTerm/Show';

import AdoptionFairShow from '~/views/AdoptionFair/Show';
import AdoptionFairShowAnimal from '~/views/AdoptionFair/Show/Animal';

import AnimalShow from '~/views/Animal/Show';

import MistreatmentShow from '~/views/Mistreatment/Show';

import Navbar from './NavbarPages/Navbar';

export default function AppRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home title="Petis web show - Home" />} />
        <Route element={<Navbar />}>
          <Route path="/rga" element={<Rga title="Petis web show - Consultar RGA" />} />
          <Route path="/rga/:uid" element={<RgaShow title="Petis web show - Consultar RGA" />} />

          <Route path="/castration" element={<Castration title="Petis web show - Consultar castração" />} />
          <Route path="/castration/:uid" element={<CastrationShow title="Petis web show - Consultar castração" />} />

          <Route path="/mistreatment/:uid" element={<MistreatmentShow title="Petis web show - Consultar denúncia" />} />

          <Route path="/batch" element={<Batch title="Petis web show - Consultar lote" />} />
          <Route path="/batch/:uid" element={<BatchShow title="Petis web show - Consultar lote" />} />

          <Route path="/clinical-care" element={<ClinicalCare title="Petis web show - Consultar atendimento clínico" />} />
          <Route path="/clinical-care/:uid" element={<ClinicalCareShow title="Petis web show - Consultar atendimento clínico" />} />

          <Route path="/adoption-term" element={<AdoptionTerm title="Petis web show - Imprimir Termo de Adoção" />} />
          <Route path="/adoption-term/:uid" element={<AdoptionTermList title="Petis web show - Histórico de Feiras do Pet" />} />
          <Route path="/adoption-term/:uid/:fairId" element={<AdoptionTermShow title="Petis web show - Termo de Adoção" />} />

          <Route path="/adoption-fairs/:id" element={<AdoptionFairShow title="Petis web show - Feira de adoção" />}>
            <Route path="/adoption-fairs/:id/animals/:animalId" element={<AdoptionFairShowAnimal title="Petis web show - Feira de adoção / Pet" />} />
          </Route>

          <Route path="/animal/:uid" element={<AnimalShow title="Petis web show - Animal" />} />
        </Route>
        <Route path="*" element={<NotFound title="Petis web show - 404" />} />
      </Routes>
    </BrowserRouter>
  );
}
